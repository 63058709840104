import i18n from "i18next"
import detector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

import translationIT from "./locales/it/translation.json"
import translationSL from "./locales/sl/translation.json"
import translationPT from "./locales/pt/translation.json"
import translationGR from "./locales/gr/translation.json"
import translationHR from "./locales/hr/translation.json"
import translationRO from "./locales/ro/translation.json"
import translationEN from "./locales/en/translation.json"

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const lang = urlParams.get('lang');

// the translations
const resources = {
  sl: {
    translation: translationSL,
  },
  gr: {
    translation: translationGR,
  },
  hr: {
    translation: translationHR,
  },
  ro: {
    translation: translationRO,
  },
  en: {
    translation: translationEN,
  },
  pt: {
    translation: translationPT,
  },
  it: {
    translation: translationIT,
  },
}

const language = lang || 'it';


i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,
    fallbackLng: "it", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
