import { useEffect, useState } from "react";

const ActiveAreas = ({handleClick, state, width}) => {
    const [componentLoaded, setComponentLoaded] = useState(false)

    const handleClickElement = (e)=> {
		
			handleClick(e);
		
    }

    useEffect(()=>{
        if(!componentLoaded) {
            const allActiveAreas = [...document.querySelectorAll('g[id^="room"] g[id^="active_area"]')]
            allActiveAreas.forEach(el => {
                el.addEventListener('click', () => {
						handleClickElement(el)
                })
            })
            setComponentLoaded(true)
        }
    },[])

    return (
        <svg width={width} id="areas" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2400 1420">
        <defs>
            <style>
            
            </style>
        </defs>
        <g id="room_3_4">
	<polygon id="active_area_3_4" className="st0" points="335.1,613.2 942.9,597.2 944.5,889 659,935.5 333.5,911.5 	"/>
	<g id="active_area_3_4_1">
		<g>
			<polyline className="st1" points="355.3,814.2 351.9,814.5 351.9,817.9 			"/>
			<line className="st2" x1="351.9" y1="825.1" x2="351.9" y2="879.6"/>
			<polyline className="st1" points="351.9,883.2 351.9,886.6 355.3,886.3 			"/>
			<line className="st3" x1="362.1" y1="885.6" x2="426.1" y2="879.3"/>
			<polyline className="st1" points="429.5,878.9 432.9,878.6 432.9,875.2 			"/>
			<line className="st4" x1="432.9" y1="868.3" x2="432.9" y2="816.5"/>
			<polyline className="st1" points="432.9,813.1 432.9,809.7 429.5,809.8 			"/>
			<line className="st5" x1="422.8" y1="810.2" x2="358.7" y2="814"/>
		</g>
		<line className="st6" x1="392.3" y1="836" x2="392.3" y2="860.5"/>
		<line className="st6" x1="376.8" y1="849.4" x2="406.5" y2="847"/>
		<polygon className="st7" points="432.9,878.6 352.6,886.6 352.1,814.1 432.9,809.7 		"/>
		<polygon className="st8" points="444.7,892.3 338.7,901 338.7,794.2 444.9,788.8 		"/>
	</g>
	<g id="active_area_3_4_2">
		<g>
			<polyline className="st9" points="679.2,660.6 675.9,660.5 675.9,663.8 			"/>
			<line className="st10" x1="675.9" y1="671.2" x2="675.9" y2="682.4"/>
			<polyline className="st9" points="675.9,686.1 675.9,689.4 679.2,689.4 			"/>
			<line className="st11" x1="685.5" y1="689.4" x2="733.5" y2="689.4"/>
			<polyline className="st9" points="736.7,689.4 740,689.4 740,686.1 			"/>
			<line className="st12" x1="740" y1="679.2" x2="740" y2="668.8"/>
			<polyline className="st9" points="740,665.4 740,662.1 736.7,662 			"/>
			<line className="st11" x1="730.3" y1="661.9" x2="682.3" y2="660.7"/>
		</g>
		<line className="st6" x1="709.5" y1="668.5" x2="709.5" y2="681.3"/>
		<line className="st13" x1="701.5" y1="674.9" x2="716.8" y2="674.9"/>
		<polygon className="st7" points="740,688.4 675.9,689.4 675.8,660.3 739.4,662.2 		"/>
		<polygon className="st8" points="749.4,704 664,705.6 663.6,647.6 748.3,651.7 		"/>
	</g>
	<g id="active_area_3_4_3">
		<g>
			<polyline className="st14" points="833,625.3 836.2,625.2 833.1,624.5 			"/>
			<polyline className="st15" points="827,623.1 800.9,617.2 719,618.7 			"/>
			<polyline className="st14" points="716,618.8 712.7,618.8 715.9,619.4 			"/>
			<polyline className="st16" points="722,620.5 757.6,626.8 829.9,625.4 			"/>
		</g>
		<polygon className="st7" points="833.1,624.5 757.6,626.8 719,619.1 800.9,617.2 		"/>
		<polygon className="st8" points="875.3,633.8 732.6,637.8 661.9,605.8 822.8,601.8 		"/>
	</g>
</g>
<g id="room_3_3">
	<polygon id="active_area_3_3" className="st0" points="673.5,937.1 1066.3,966 1258.8,924.3 1245.9,922.7 1245.9,605.2 1201.1,594 
		952.5,597.2 952.5,889 	"/>
	<g id="active_area_3_3_3">
		<g>
			<polyline className="st14" points="1149,618.2 1152.5,618.1 1149.1,617.2 			"/>
			<polyline className="st15" points="1142.7,615.4 1114.8,607.6 1027.4,609.6 			"/>
			<polyline className="st14" points="1024.1,609.6 1020.7,609.7 1024,610.5 			"/>
			<polyline className="st16" points="1030.5,611.8 1068.6,620.2 1145.7,618.2 			"/>
		</g>
		<polygon className="st7" points="1150.4,617.3 1069.8,620.3 1028.6,610.2 1116,607.7 		"/>
		<polygon className="st8" points="1187.3,628.4 1044.6,632.4 973.9,600.4 1134.8,596.4 		"/>
	</g>
	<g id="active_area_3_3_1">
		<g>
			<polyline className="st17" points="974.2,801.3 970.9,801.6 970.9,804.9 			"/>
			<line className="st18" x1="970.9" y1="811.1" x2="970.9" y2="857.8"/>
			<polyline className="st17" points="970.9,860.9 970.9,864.2 974.2,863.8 			"/>
			<line className="st19" x1="980.8" y1="863" x2="1017.3" y2="858.5"/>
			<polyline className="st17" points="1020.6,858.2 1023.8,857.8 1023.8,854.5 			"/>
			<line className="st20" x1="1023.8" y1="848.4" x2="1023.8" y2="803.1"/>
			<polyline className="st17" points="1023.8,800.1 1023.8,796.8 1020.6,797.1 			"/>
			<line className="st21" x1="1013.9" y1="797.7" x2="977.5" y2="801"/>
		</g>
		<line className="st13" x1="985.4" y1="831.7" x2="1007.8" y2="828.5"/>
		<line className="st13" x1="996.6" y1="819.7" x2="996.6" y2="840.5"/>
		<polygon className="st7" points="1023.8,858.2 971.3,864.2 970.9,801.7 1023.8,797.8 		"/>
		<polygon className="st8" points="1038,875.2 952.1,886.6 952.1,787.2 1036.9,777.1 		"/>
	</g>
	<g id="active_area_3_3_2">
		<g>
			<polyline className="st9" points="990.2,660.5 987,660.5 987,663.8 			"/>
			<line className="st10" x1="987" y1="671.2" x2="987" y2="682.4"/>
			<polyline className="st9" points="987,686.1 987,689.4 990.2,689.4 			"/>
			<line className="st11" x1="996.7" y1="689.4" x2="1044.6" y2="689.4"/>
			<polyline className="st9" points="1047.8,689.4 1051.1,689.4 1051.1,686.1 			"/>
			<line className="st10" x1="1051.1" y1="678.7" x2="1051.1" y2="667.5"/>
			<polyline className="st9" points="1051.1,663.8 1051.1,660.5 1047.8,660.5 			"/>
			<line className="st11" x1="1041.4" y1="660.5" x2="993.5" y2="660.5"/>
		</g>
		<line className="st6" x1="1019" y1="670" x2="1019" y2="682.8"/>
		<line className="st13" x1="1011" y1="676.4" x2="1026.2" y2="676.4"/>
		<polygon className="st7" points="1051.5,688.6 987.3,689.6 987.3,660.5 1050.8,662.4 		"/>
		<polygon className="st8" points="1061.9,702.5 975.5,703.1 975.1,645.1 1060.8,650.2 		"/>
	</g>
</g>
<g id="room_3_2">
	<polygon id="active_area_3_2" className="st0" points="952.5,563.5 952.5,128.9 1250.8,84 1473.7,204.3 1473.7,581.1 1258.8,557.1 
		952.5,565.1 	"/>
	<g id="active_area_3_2_2">
		<g>
			<polyline className="st9" points="998.2,318.2 995,317.3 995,320.6 			"/>
			<line className="st22" x1="995" y1="328.6" x2="995" y2="340.5"/>
			<polyline className="st9" points="995,344.5 995,347.8 998.2,348.6 			"/>
			<line className="st23" x1="1004.1" y1="350.2" x2="1036.9" y2="359"/>
			<polyline className="st9" points="1039.9,359.8 1043.1,360.6 1043.1,357.3 			"/>
			<line className="st22" x1="1043.1" y1="349.4" x2="1043.1" y2="337.4"/>
			<polyline className="st9" points="1043.1,333.5 1043.1,330.2 1039.9,329.3 			"/>
			<line className="st23" x1="1033.9" y1="327.7" x2="1001.1" y2="319"/>
		</g>
		<line className="st6" x1="1019" y1="331.8" x2="1019" y2="346.2"/>
		<line className="st6" x1="1011" y1="337.2" x2="1027.1" y2="341.4"/>
		<polygon className="st7" points="1042.9,360.5 995.6,347.5 995.6,317.8 1042.9,331 		"/>
		<polygon className="st8" points="1065.5,372.3 969.8,353.9 969.5,296 1064.5,320.1 		"/>
	</g>
	<g id="active_area_3_2_1">
		<g>
			<polyline className="st17" points="972.6,495.5 969.3,495.3 969.3,495.3 			"/>
			<line className="st24" x1="969.3" y1="505.2" x2="969.3" y2="554.5"/>
			<polyline className="st17" points="969.3,557.8 969.3,561.1 972.6,561.1 			"/>
			<line className="st25" x1="979" y1="561.1" x2="1014.2" y2="561.1"/>
			<polyline className="st17" points="1017.3,561.1 1020.6,561.1 1020.6,557.8 			"/>
			<line className="st18" x1="1020.6" y1="551.6" x2="1020.6" y2="504.9"/>
			<polyline className="st17" points="1020.6,501.8 1020.6,498.5 1017.3,498.3 			"/>
			<line className="st26" x1="1011" y1="497.9" x2="975.8" y2="495.7"/>
		</g>
		<line className="st13" x1="995" y1="517.4" x2="995" y2="538.2"/>
		<line className="st13" x1="983.8" y1="527.8" x2="1004.6" y2="529"/>
		<polygon className="st7" points="1020.6,561.1 969.3,561.1 969.3,495.3 1020.6,498.5 		"/>
		<polygon className="st8" points="1040.5,568.9 952.3,566 952.3,479.3 1041.1,487 		"/>
	</g>
	<g id="active_area_3_2_3">
		<polygon className="st8" points="1213.5,272.6 1066.8,292.1 970.5,248.5 1124.8,227.7 		"/>
		<g>
			<polyline className="st14" points="1034.9,262.2 1031.9,261.2 1035.1,260.9 			"/>
			<polyline className="st27" points="1041.5,260.2 1121.7,251.6 1152.5,262.3 			"/>
			<polyline className="st14" points="1155.5,263.4 1158.6,264.4 1155.4,264.7 			"/>
			<polyline className="st28" points="1148.9,265.2 1067.1,272.4 1038,263.1 			"/>
		</g>
		<line className="st29" x1="1088" y1="259.6" x2="1102.4" y2="266"/>
		<line className="st29" x1="1083.2" y1="264.4" x2="1107.2" y2="261.2"/>
		<polygon className="st7" points="1156.8,264 1067.5,272 1033.5,261.3 1121.8,251.5 		"/>
	</g>
</g>
<g id="room_3_1">
	<polygon id="active_area_3_1" className="st0" points="946.1,130.5 946.1,266.8 944.5,565.1 336.7,584.3 336.7,221.9 	"/>
	<g id="active_area_3_1_3">
		<g>
			<polyline className="st30" points="736.7,295.5 733.6,294.9 736.8,294.5 			"/>
			<polyline className="st31" points="743,293.7 810.6,285.3 850.9,295 			"/>
			<polyline className="st30" points="854,295.7 857.1,296.5 853.9,296.8 			"/>
			<polyline className="st32" points="847.7,297.4 778.5,304.5 739.8,296.2 			"/>
		</g>
		<line className="st29" x1="790.5" y1="292" x2="804.9" y2="298.4"/>
		<line className="st29" x1="785.6" y1="296.8" x2="809.7" y2="293.6"/>
		<polygon className="st7" points="855.5,296.3 779.2,304.5 736.2,294.6 813.5,284.8 		"/>
		<polygon className="st8" points="906.8,303.5 787.1,319.4 673.8,289.4 799.9,264.8 		"/>
	</g>
	<g id="active_area_3_1_2">
		<g>
			<polyline className="st9" points="498.7,299.5 495.5,298.9 495.5,302.2 			"/>
			<line className="st33" x1="495.5" y1="310.7" x2="495.5" y2="323.4"/>
			<polyline className="st9" points="495.5,327.7 495.5,331 498.7,331.5 			"/>
			<line className="st34" x1="505.2" y1="332.7" x2="566.7" y2="343.5"/>
			<polyline className="st9" points="570,344 573.2,344.6 573.2,341.3 			"/>
			<line className="st33" x1="573.2" y1="332.8" x2="573.2" y2="320.1"/>
			<polyline className="st9" points="573.2,315.8 573.2,312.5 570,312 			"/>
			<line className="st34" x1="563.5" y1="310.8" x2="501.9" y2="300"/>
		</g>
		<line className="st6" x1="536" y1="313.8" x2="536" y2="331.1"/>
		<line className="st6" x1="524.7" y1="320.3" x2="547.2" y2="324.6"/>
		<polygon className="st7" points="573.2,343.9 496.1,330.7 495.1,298.9 572.4,313.1 		"/>
		<polygon className="st8" points="587.6,354.6 482.2,336.2 481.9,278.3 586.6,302.4 		"/>
	</g>
	<g id="active_area_3_1_1">
		<g>
			<polyline className="st17" points="356.8,484.4 353.5,484.1 353.5,487.4 			"/>
			<line className="st35" x1="353.5" y1="493.5" x2="353.5" y2="551.5"/>
			<polyline className="st17" points="353.5,554.6 353.5,557.9 356.8,558.1 			"/>
			<line className="st36" x1="363.4" y1="558.5" x2="426.3" y2="562.3"/>
			<polyline className="st17" points="429.6,562.5 432.9,562.7 432.9,559.4 			"/>
			<line className="st37" x1="433" y1="552.3" x2="433.6" y2="499"/>
			<polyline className="st17" points="433.7,495.4 433.7,492.1 430.4,491.8 			"/>
			<line className="st38" x1="423.7" y1="491.1" x2="360.1" y2="484.8"/>
		</g>
		<line className="st6" x1="395.5" y1="511.2" x2="395.5" y2="535.8"/>
		<line className="st6" x1="380" y1="522.2" x2="409.6" y2="524.8"/>
		<polygon className="st7" points="432.9,562.7 353.3,557.5 353.3,484.8 433.7,492.4 		"/>
		<polygon className="st8" points="444,576.3 340.6,572.7 340.6,465.8 444.7,475.4 		"/>
	</g>
</g>
        </svg>
    )
}

export default ActiveAreas;