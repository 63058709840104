import {useEffect, useState} from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Tooltip from './tooltip';

const ContentModalThirdStep = ({ data, handleSelection, firstStepSelection, position, limits, handleClose, isFirstInternUnit }) => {
  console.log('firstStepSelection', firstStepSelection)
  const { t } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipMessage, setTooltipMessage] = useState(t('Non ci sono prodotti compatibili per questa configurazione'))
  const [selectionProduct, setSelectionProduct] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedProductVariation, setSelectedProductVariation] = useState(null)
  const forceNumber = (value) => {
    const stringValue = String(value).replace(',', '.')
    return Number(stringValue)
  }
  const [elements, setElements] = useState(null)
  const [title, setTitle] = useState(null)

  const groupBy = (xs, key) => {
    // console.log(xs, key)
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const prepareData = (array, key) => {
    setSelectionProduct(array)
    const tmp = groupBy(array, key)
    const data = []
    for (const [key, value] of Object.entries(tmp)) {
      const obj = {
        model: key,
        family: value[0].Famiglia,
        id: value[0].ID,
        childs: value
      }
      data.push(obj)
    }
    return data
  }

  const getProductVariation = (variation) => {
    return selectionProduct.filter((prod) => prod.Variante === variation)[0]
  }

  useEffect(()=>{
    const [step, room, element] = position.split('_')
    let firstLevel = null
    switch(Number(element)) {
      case 1:
        setElements(prepareData([...data.Pavimento],'Modello'))
        setTitle(t('Seleziona Unità a Pavimento'))
        break;
      case 2:
        setElements(prepareData([...data.Parete],'Modello'))
        setTitle(t('Seleziona Unità a Parete'))
        break;
      case 3:

        setElements(prepareData([...data.Canalizzata, ...data.Soffitto],'Modello'))
        setTitle(t('Seleziona Unità Canalizzata o a Soffitto'))
      break;
      default:
        break;
    }

  }, [])

  const checkLimits = (prod) => {
    const isValidVIndex = limits.maxLimits.vIndex - limits.currentLimits.vIndex - forceNumber(prod['Indice Vi']) >= 0
    const isValidCapacity = limits.maxLimits.capacity - limits.currentLimits.capacity - forceNumber(prod['Indice capacità']) >= 0
    if (isFirstInternUnit) {

      return isValidCapacity && isValidVIndex
    } else {
      return isValidCapacity && isValidVIndex && !isPeadPcaProduct(prod)
    }
  } 
  const isValidOption = (prod) => {
    console.log('isValidOption', prod)
    if (firstStepSelection.Modello === 'PXZ-4F75VG' && prod.Variante === 'SEZ-M71') {
      return false
    }
    if (firstStepSelection.Modello === 'PXZ-5F85VG' && prod.id === 'PCA-M') {
      return false
    }
    return true
  }
  const isPeadPcaProduct = (product) => {
    const variant = product.model || product.Variante
    const regex = /(PCA-M60)|(PEAD-M60)|(PEAD-M71)/g;
    const found = variant.match(regex);
    return found !== null && found.length > 0
  }
  const isProductSelectable = (el) => {
    
    if(el.child && el.child.length > 0){

      const totChilds = el.childs.length
      const notAvailableChilds = el.childs.filter(child => {
        return !checkLimits(child)
      }) 
      
      if (isFirstInternUnit) {
        return totChilds - notAvailableChilds.length > 0
      } else {
        const isPeadPcaProductBool = isPeadPcaProduct(el)
        return totChilds - notAvailableChilds.length > 0 && !isPeadPcaProductBool
      }
    } else {
      if (isFirstInternUnit) {
        return !checkLimits(el)
      } else {
        const isPeadPcaProductBool = isPeadPcaProduct(el)
        console.log('isProductSelectable', el, !checkLimits(el), !isPeadPcaProductBool)
        return !checkLimits(el) && !isPeadPcaProductBool
  
      }
      // return true
    }
  }

  return (
    elements && elements.length > 0 ? 
    <>
      <div className="wrapper-title">
        3. {title}
      </div>
      <motion.div className={`wrapper-elements step-3 ${selectedProduct !== null ? 'is-child' : 'is-parent'}`}>
        {elements.map((el, i) => {
          return (
            <div 
              
              className={`wrapper-element ${selectedProduct === i ? 'selected' : ''} ${isProductSelectable(el) ? '' : 'disabled'} ${!isValidOption(el) ? 'not-valid':''}`}
              key={el.model}
            >
              <div className="cover" 
                onMouseOver={()=>{
                  if(!isProductSelectable(el)){
                    setShowTooltip(true)
                    if(isPeadPcaProduct(el)) setTooltipMessage(t('impossibile installare con altre unità ad espansione'))
                    else setTooltipMessage(t('la potenza dell\'impianto non è sufficiente'))
                  }
                }}
                onMouseOut={()=>{
                  setShowTooltip(false)
                }}
                onClick={()=>{
                  if (isProductSelectable(el)) setSelectedProduct(i)
                }}
              >
                <div className="wrapper-img">
                  <img src={`/img/Thumbnails/thumb_${el.id}.jpg`} alt={el.model} />
                </div>
                <div className="wrapper-data">
                  <div className="name">{el.model}</div>
                  {el.family && <div className="family">{el.family}</div>}
                </div>
              </div>
              <div className="wrapper-childs">
                <table>
                  <thead>
                    <tr>
                      <th className="name">
                        &nbsp;
                      </th>
                      <th className="capacity">
                        {t('Capacità')}
                      </th>
                      <th className="v-index">
                        {t('Indice Vi')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    el.childs.map( (child, c) => {
                      // Indice Vi - Indice capacità
                      return <tr 
                        style={{opacity: checkLimits(child) ? 1:.2,display: isValidOption(child) ? 'table-row':'none'}}
                        className={`child ${selectedProductVariation && selectedProductVariation.index === c ? 'selected' : ''}` }
                        key={child.Variante}
                        onMouseOver={() => {
                          if (!checkLimits(child) ) {
                            setTooltipMessage(isPeadPcaProduct(child) ? 
                              t('impossibile installare con altre unità ad espansione') : 
                              t('la potenza dell\'impianto non è sufficiente')
                            )
                            setShowTooltip(true)
                          } 
                        }}
                        onMouseOut={() => {
                          setShowTooltip(false) 
                        }}
                        onClick={() => {
                          if (checkLimits(child)) {
                            setSelectedProductVariation({index:c, variation:child.Variante})
                          } 
                        }}
                      >
                        <td className="name">
                          {child.Variante}
                        </td>
                        <td className="capacity">
                          <div className="content-cell">
                            {child['Indice capacità']}
                          </div>
                        </td>
                        <td className="v-index">
                          <div className="content-cell">
                            {child['Indice Vi']}
                          </div>
                        </td>
                      </tr>
                    }
                    )
                  }
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </motion.div>
      <div className="wrapper-cta">
          <div 
            
            className="modal-button back"
            onClick={() => {
              if (selectedProduct !== null) {
                setSelectedProduct(null)
                setSelectedProductVariation(null)
              } else {
                  handleClose(false)
                }
              }
            }
          >
            {t('INDIETRO')}
          </div>
          <div 
            className="modal-button"
            style={{
              display: selectedProductVariation !== null ? 'inline-block':'none'
            }}
            onClick={() => {
              if(selectedProductVariation?.variation){
                handleSelection(getProductVariation(selectedProductVariation.variation), 'interne', position)
              }
            }}
          >
            {t('AGGIUNGI')}
          </div>
      </div>
      <Tooltip show={showTooltip} message={tooltipMessage}/>
    </> : <>loading...</> 
  )
};

export default ContentModalThirdStep;
