const ContentPdf = ({ currentSelection, children, extraClass, locale, lang }) => {
  
  const isPage2 = [...extraClass.split(' ')].includes('page-2');
  const page1Style = {
    background: `url('/img/pdf/${locale}/${lang}/ECODAN-PAG1.jpg') no-repeat top left` ,
    backgroundSize: 'cover',
  }
  const page2Style = {
    background: `url('/img/pdf/${locale}/${lang}/PAG2__${currentSelection}.jpg') no-repeat top left` ,
    backgroundSize: 'cover',
  }




  return (
    <div className={`wrapper-pdf ${extraClass}`} style={!isPage2 ? page1Style : page2Style}>
      {children}
    </div>)
};
export default ContentPdf;
