import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';
const ContentModalSecondStep = ({ data, handleSelection, currentSelection }) => {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState(null)
  const getSelectedProduct = (currentSelection) => {
    console.log('getSelectedProduct', currentSelection)
    currentSelection.map(selected => {
      console.log('selected', selected)
      data.map((product, i) => {
        if (product.Variante === selected.Variante) {
          console.log('getSelectedProduct', product, selected[0])
          setSelectedProduct(i)
        }
      })
    })
  }
  useEffect(()=>{
    if (currentSelection?.length > 0) getSelectedProduct(currentSelection)
  }, [])
  
  return (
    <>
    <div className="image-brand">
        <img src="/img/ecodan.svg" width={100} alt="" />
      </div>
      <div className="wrapper-title">
        {t('2. Selezione Unità Idronica')}
      </div>
      <div className="wrapper-elements step-2">
        {data.map((el, i) => {
          return (
            <div 
              className={`wrapper-element ${selectedProduct === i ? 'selected' : ''}`}
              key={el.Variante}
              onClick={()=>{
                setSelectedProduct(i)
              }}
            >
              <div className="wrapper-img">
                <img src={`/img/Thumbnails/thumb_${el.ID}.jpg`} alt={el.Variante} />
              </div>
              <div className="wrapper-data">
                <div className="name">{el.Modello}</div>
                <div className="family">{el.Variante}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="wrapper-cta">
          <div 
            className="modal-button"
            onClick={() => {
              if(selectedProduct !== null){
                handleSelection([data[selectedProduct]], 'idroniche')
              }
            }}
          >
            {t('AGGIUNGI')}
          </div>
      </div>
    </>
  );
};

export default ContentModalSecondStep;
