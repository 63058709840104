// import icopdf from "../assets/pdf.svg";
// import icoverifica from "../assets/verifica.svg";
// import plus from "../assets/plus.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import UnitBlock from "./unitBlock";

const UnitBlocks = ({
  openModal,
  handlePreview,
  productSelection,
  state,
  handleClickPrint,
  handleChangeInternUnit,
}) => {
  const { t } = useTranslation();
  console.log("productSelection", productSelection);
  useEffect(() => {
    const allImagesBtn = document.querySelectorAll(
      ".unit-block.single .wrapper-cta-image"
    );
    allImagesBtn.forEach((el) => {
      el.addEventListener("click", () => {
        openModal(el);
      });
    });
  }, []);

  const handlePreviewClick = () => {
    handlePreview(true);
  };

  const handleClick = (position, sku) => {
    console.log("handleChangeInternUnit", handleChangeInternUnit);
    handleChangeInternUnit(position, sku);
  };
  return (
      <div className="scroll">
    <div className="wrapper-unit-blocks">
      <div className="inline-blocks">

        <div
          className={`unit-block single ${
            state.step1Enabled == true ? "" : "disabled"
          }`}
        >
          <div className="label">1. {t('Unità Esterna')}</div>
          <div className="block">
            <div className="wrapper-block">
              <div className="wrapper-cta-image" data-step="1">
                {productSelection && productSelection.esterne.length > 0 ? (
                  <img
                    src={`/img/Thumbnails/thumb_${productSelection.esterne[0].Variante}.jpg`}
                    alt={productSelection.esterne[0].Variante}
                  />
                ) : (
                  <img src="/img/plus.svg" alt="" />
                )}
              </div>
              {productSelection && productSelection.esterne.length > 0 ? (
                <div className="label">
                  {productSelection.esterne[0].Variante}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div
          className={`unit-block single ${
            state.step2Enabled == true ? "" : "disabled"
          }`}
        >
          <div className="label">{t('2. Unità Idronica')}</div>
          <div className="block">
            <div className="wrapper-block">
              <div className="wrapper-cta-image" data-step="2">
                {productSelection && productSelection.idroniche.length > 0 ? (
                  <img
                    src={`/img/Thumbnails/thumb_${productSelection.idroniche[0].ID}.jpg`}
                    alt={productSelection.idroniche[0].ID}
                  />
                ) : (
                  <img src="/img/plus.svg" alt="" />
                )}
              </div>
              {productSelection && productSelection.idroniche.length > 0 ? (
                <div className="label">
                  {productSelection.idroniche[0].Variante}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

        <div
          className={`unit-block ${
            state.step3Enabled == true && productSelection.interne.length > 0
              ? ""
              : "disabled"
          }`}
        >
          <div className="label">{t('3. Unità Interne')}</div>
          <div className={`wrapper-blocks`}>
            {productSelection.interne.length === 0 &&
              state.step3Enabled == true && (
                <div className="message">
                  {t('Seleziona la stanza per installare una unità interna')}
                </div>
              )}
            <UnitBlock
              item={productSelection.interne[0]}
              handleClick={handleClick}
            />
            <UnitBlock
              item={productSelection.interne[1]}
              handleClick={handleClick}
            />
            <UnitBlock
              item={productSelection.interne[2]}
              handleClick={handleClick}
            />
            <UnitBlock
              item={productSelection.interne[3]}
              handleClick={handleClick}
            />
          </div>
        </div>
        <div
          style={{marginTop: 30}}
          className={`unit-block ${
            state.step4Enabled == true ? "" : "disabled"
          }`}
        >
          <div className="label">{t('4. Download')}</div>
          <div className="wrapper-blocks">
            <div className="block">
              <div className="wrapper-cta">
                <div className="btn verifica" onClick={handlePreviewClick}>
                  {t('verifica')}
                  <img src="/img/verifica.svg" alt="" />
                </div>
                <div
                  className="btn download"
                  onClick={() => {
                    handleClickPrint();
                  }}
                >
                  {t('download')}
                  <img src="/img/pdf.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitBlocks;
