import {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next';

const ContentModalFirstStep = ({ data, handleSelection, currentSelection }) => {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState(null)
  const getSelectedProduct = (currentSelection) => {
    console.log('getSelectedProduct', currentSelection)
    currentSelection.map(selected => {
      console.log('selected', selected)
      data.map((product, i) => {
        if (product.Variante === selected.Variante) {
          console.log('getSelectedProduct', product, selected[0])
          setSelectedProduct(i)
        }
      })
    })
  }
  useEffect(()=>{
    if (currentSelection?.length > 0) getSelectedProduct(currentSelection)
  }, [])
  
  return (
    <>
      <div className="image-brand">
        <img src="/img/ecodan-multi.svg" width={100} alt="" />
      </div>
      <div className="wrapper-title">
        {t('1. Selezione Unità Esterna')}
      </div>
      <div className="wrapper-elements step-1">
        {data.map((el, i) => {
          return (
            <div 
              className={`wrapper-element ${selectedProduct === i ? 'selected' : ''}`}
              key={el.Variante}
              onClick={()=>{
                setSelectedProduct(i)
              }}
            >
              <div className="wrapper-img">
                <img src={`/img/Thumbnails/thumb_${el.Variante}.jpg`} alt={el.Variante} />
              </div>
              <div className="wrapper-data">
                <div className="name">{el.Variante}</div>
                <div className="table">
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('N. unità idroniche')}</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>{t('N. max unità collegabili dx')}</td>
                        <td>{el["N. max unità collegabili"]}</td>
                      </tr>
                      <tr>
                        <td>{t('Capacità max. collegabile')}</td>
                        <td>{el["Capacità max. collegabile"]}</td>
                      </tr>
                      <tr>
                        <td>{t('Indice Vi max. collegabile')}</td>
                        <td>{el["Indice Vi max. collegabile"]}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="wrapper-cta">
          <div 
            className="modal-button"
            onClick={() => {
              if(selectedProduct !== null){
                handleSelection([data[selectedProduct]], 'esterne')
              }
            }}
          >
            {t('AGGIUNGI')}
          </div>
      </div>
    </>
  );
};

export default ContentModalFirstStep;
