const UnitBlock = ({item, handleClick, index}) => {
    return (
        <div 
            className={`block ${item?.product ? 'populated' : ''}`}
            onMouseOver={()=>{
                if (item?.product) {
                  const over = document.querySelector(`[id="active_area_${String(item?.position).slice(0,-2)}"]`)
                  over.classList.add('active')
                }
              }}
              onMouseOut={()=>{
                if (item?.product) {
                  const over = document.querySelector(`[id="active_area_${String(item?.position).slice(0,-2)}"]`)
                  over.classList.remove('active')
                }
              }}
              
        >
            <div className="wrapper-block">
                
                <div
                className="wrapper-cta-image"
                data-step="3"
                data-element="1"
                
                >
                  {item?.product && <div 
                  className="btn-delete"
                  onClick={()=>{
                    if (item?.product) {
                        handleClick(item.position, item.product.Variante)
                    }
                  }}
                >
                  <img src="/img/remove.svg" alt="" />
                </div>}
                {item?.product ? (
                <img
                src={`/img/Thumbnails/thumb_${item.product.Modello}.jpg`}
                alt={item.product.Modello}
                />
                ) : null }
                </div>
                {item?.product ? (
                    <div className="label">{item.product.Variante}</div>
                    ) : null}
            </div>
        </div>
    )
}

export default UnitBlock