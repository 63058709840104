import { AnimatePresence, motion } from 'framer-motion';

const Modal = ({modalIsOpen, handleClose, children, showClose}) => {
    const overlayVariants = {
        visible: {
          opacity: 1,
          transition: {
            when: "beforeChildren",
            duration: 0.3,
            delayChildren: 0.4
          }
        },
        hidden: {
          opacity: 0,
          transition: {
            when: "afterChildren",
            duration: 0.3,
            delay: 0.4
          }
        }
      };

    return(
        <AnimatePresence>
        {modalIsOpen && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            className="modal-overlay"
          >
            <motion.div
              className="modal"
              initial={{ y: 100, opacity: 0}}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -100, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <img src="/img/ME-payoff.svg" width={100} alt="" />
                  {showClose && 
                    <div className="close">
                      <img src="/img/plus.svg" alt="" onClick={handleClose}/>
                    </div>
                  }
                </div>
                <div className="scroll-content">
                  {children}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    )
}

export default Modal;