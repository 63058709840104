import {useEffect, useState} from 'react'
import {motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const ContentModalPreview = ({ currentSelection }) => {
  const { t } = useTranslation();
  const forceNumber = (value) => {
    const stringValue = String(value).replace(',', '.')
    return Number(stringValue)
  }
  const removeEmpty = (el) => {
    return typeof el === 'object' && el !== null
  }
  const [productsList, setProductList] = useState(null)
  useEffect(()=>{
    console.log('currentSelection', currentSelection)
    if (currentSelection) {

      const list = currentSelection.interne.filter(removeEmpty)
      setProductList(list)
    }
  }, [])
  

  const getTotals = (products) => {
    let totCapacity = 0, 
    totVIndex = 0

    products.map((prod) => {
      const capacity = forceNumber(prod.product['Indice capacità'])
      const vIndex = forceNumber(prod.product['Indice Vi'])
      totCapacity = totCapacity + capacity
      totVIndex = totVIndex + vIndex
    })

    return [totCapacity, totVIndex.toFixed(2)]
  }



  return (
    <>
      <div className="wrapper-title">
      {t('La tua configurazione sistema Ecodan')} 
      </div>
      <motion.div className={`wrapper-preview wrapper-elements step-3`}>
        <table>
          <thead>
            <tr>
              <th className="unit">
                &nbsp;
              </th>
              <th className="name">
                &nbsp;
              </th>
              <th className="capacity">
                {t('Capacità')}
              </th>
              <th className="v-index">
                {t('Indice Vi')}
              </th>
            </tr>
          </thead>
          <tbody>
          {
            productsList && productsList.map( (child, c) => {
              // Indice Vi - Indice capacità
              if(child?.product){
                return <tr 
                  className={`child interne`}
                  key={c}
                >
                  <td className="unit">
                    {t('Unità interne')}
                  </td>
                  <td className="name">
                    {child.product.Variante}
                  </td>
                  <td className="capacity">
                    <div className="content-cell">
                      {child.product['Indice capacità']}
                    </div>
                  </td>
                  <td className="v-index">
                    <div className="content-cell">
                      {child.product['Indice Vi']}
                    </div>
                  </td>
                </tr>
              }
            })
          }
          
          <tr className='child totals'>
            <td className="unit">
              {t('Tot. indici interne')}
            </td>
            <td className="name">
              &nbsp;
            </td>
            <td className="capacity">
              <div className="content-cell">
                {productsList && getTotals(productsList)[0]}
              </div>
            </td>
            <td className="v-index">
              <div className="content-cell">
                {productsList && getTotals(productsList)[1]}
              </div>
            </td>
          </tr>
          
          {
            currentSelection.esterne.map( (child, c) => {
              // Indice Vi - Indice capacità
              
                return <tr 
                  className={`child`}
                  key={c}
                >
                  <td className="unit">
                  {t('Unità Esterna')}
                  </td>
                  <td className="name">
                    {child.Variante}
                  </td>
                  <td className="capacity">
                    <div className="content-cell">
                      {child['Capacità max. collegabile']}
                    </div>
                  </td>
                  <td className="v-index">
                    <div className="content-cell">
                      {child['Indice Vi max. collegabile']}
                    </div>
                  </td>
                </tr>
            })
          }
{
            currentSelection.idroniche.map( (child, c) => {
              // Indice Vi - Indice capacità
              
                return <tr 
                  className={`child`}
                  key={c}
                >
                  <td className="unit">
                    {t('Unità idronica')}
                  </td>
                  <td className="name">
                    {child.Modello} <br />
                    <small>{child.Variante}</small>
                  </td>
                  <td className="capacity">
                    &nbsp;
                  </td>
                  <td className="v-index">
                  &nbsp;
                  </td>
                </tr>
            })
          }
          </tbody>
        </table>
      </motion.div>
    </>)
};

export default ContentModalPreview;
