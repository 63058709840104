import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Tooltip = ({message, show}) => {
    const [mousePosition, setMousePosition] = useState({
      x: 0,
      y: 0,
    });
  
    useEffect(() => {
      const updateMousePosition = (e) => {
        setMousePosition({
          x: e.clientX,
          y: e.clientY,
        });
      };
  
      window.addEventListener('mousemove', updateMousePosition);
  
      return () => {
        window.removeEventListener('mousemove', updateMousePosition);
      };
    }, []);
  
    const style = {
      maxWidth:150,
      position: 'fixed',
      left:0,
      top:0,
      transform:`translate(${mousePosition.x + 20}px, ${mousePosition.y + 20}px)`,
      transition:'opacity .1s ease',
      width: 'auto',
      display: 'inline',
      minHeight: '20px',
      padding: '10px',
      backgroundColor: 'rgb(255, 67, 75)',
      zIndex: 2,
    };
  
    const variants = {
      default: {
        opacity: show ? 1 : 0
      },
    };
  
    return (
      <motion.div
        className={`tooltip`}
        style={style}
        variants={variants}
        animate="default"
        transition={{
          opacity:{
            duration: 0.2,
            repeat:0
          },
          default: {
            duration: 0,
            repeat: Infinity,
          },
        }}
      >
        <span>{message}</span>
      </motion.div>
    );
  };

  export default Tooltip