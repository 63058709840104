import { ProductsProvider } from "./context/ProductsContext";
import Home from "./pages/Home";
import "./i18n";
function App() {
  
  return(
    <ProductsProvider>
      <Home />
    </ProductsProvider>
  )
}

export default App;
