import { useEffect, useState, useRef } from "react";
import ActiveAreas from "../components/activeAreas";
import Modal from "../components/modal";
import home from "../assets/HOME.jpg";
import { page2pdf } from "../components/page2pdf";
import html2canvas from "html2canvas";
import { PDFDocument, PageSizes } from "pdf-lib";
import { Translation, useTranslation } from 'react-i18next';
import ChangeLang from "../components/ChangeLang";
import UnitBlocks from "../components/unitBlocks";
import ContentModalFirstStep from "../components/contentModalFirstStep";
import ContentModalSecondStep from "../components/contentModalSecondStep";
import ContentModalThirdStep from "../components/contentModalThirdStep";
import ContentModalPreview from "../components/contentModalPreview";
import ContentPdf from "../components/contentPdf";
import "../App.css";


function Home() {

  const {t} = useTranslation();
  const defaultStateSelection = {
    esterne: [],
    idroniche: [],
    interne: [],
  };

  const [isPeadPcaSelected, setIsPeadPcaSelected] = useState(false);
  const [isFirstInternUnit, setIsFirstInternUnit] = useState(true);
  const [locale, setLocale] = useState(null);
  const [lang, setLang] = useState(null);
  const [imageW, setImageW] = useState('100%');

  const imageRef = useRef(null);
  
  const handleResize = () => {
    const imageW = imageRef.current && imageRef.current.offsetWidth ? imageRef.current.offsetWidth : '100%'
    console.log('imageW', imageW)
    setImageW(imageW) 
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setLocale(urlParams.get('locale') || 'it');
    setLang(urlParams.get('lang') || 'it');
  }, [])
  


  const defaultState = {
    step1Enabled: true,
    step2Enabled: false,
    step3Enabled: false,
    step4Enabled: false,
    room1Available: true,
    room2Available: true,
    room3Available: true,
    room4Available: true,
    reachedLimitUnit: false,
    reachedLimitCapacity: false,
    reachedLimitVIndex: false,
  };

  const [productSelection, setProductSelection] = useState(
    defaultStateSelection
  );

  const [printPdf, setPrintPdf] = useState(false);
  const [showHelp, setShowHelp] = useState(true);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showRoomModalChange, setShowRoomModalChange] = useState(false);
  const [showRoomModalError, setShowRoomModalError] = useState(false);
  const [showConfirmFirstStep, setShowConfirmFirstStep] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessageOpen, setModalMessageOpen] = useState(true);
  const [dataset, setDataset] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [productBgs, setProductBgs] = useState(null);
  const [positionRoom, setPositionRoom] = useState(null);
  const [maxLimits, setMaxLimits] = useState(null);
  const [areaClick, setAreaClick] = useState(null);
  const [currentLimits, setCurrentLimits] = useState({
    unit: 0,
    capacity: 0,
    vIndex: 0,
  });

  const [state, setState] = useState(defaultState);

  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    console.log("state updated", state, 'isPeadPcaSelected', isPeadPcaSelected);
  }, [state]);

  const checkErrors = (current, max) => {
    if (current && max) {
      for (const [key, value] of Object.entries(current)) {
        let limit = 0;
        if (key === "capacity") limit = 14;
        if (key === "vIndex") limit = 4.4;
        console.log(
          "reachedLimit" + key.charAt(0).toUpperCase() + key.slice(1),
          max[key] - value <= limit,
          max[key],
          value,
          key
        );
        updateState(
          "reachedLimit" + key.charAt(0).toUpperCase() + key.slice(1),
          max[key] - value <= limit
        );
      }
    }
  };

  const reduceArray = (acc, obj) => {
    if (!acc[obj.Step]) acc[obj.Step] = {};
    if (!acc[obj.Step][obj.Tipo]) acc[obj.Step][obj.Tipo] = [];
    acc[obj.Step][obj.Tipo].push(obj);
    return acc;
  };

  const getLocalePath = (locale) => {
    let path = ''
    switch (locale) {
      case "it":
        path = locale;
        break
      case "pt":
        path = locale;
        break
      default:
        path = "generic";
        break
    }
    console.log('getLocalePath', path)
    return path
  }

  const fetchData = async () => {
    
    
    fetch("/dataset/"+getLocalePath(locale)+"/data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (res) {
        const groupedObjects = res.reduce(reduceArray, {});
        console.log(groupedObjects);
        setDataset(groupedObjects);
        setIsLoaded(true);
      });
  };
  useEffect(() => {
    if (!dataset && !isLoaded && locale) {
      fetchData();
      handleResize()
      window.addEventListener("resize", handleResize)
    }
  }, [locale]);

  const forceNumber = (value) => {
    const stringValue = String(value).replace(",", ".");
    // console.log('forceNumber', value, stringValue)
    return Number(stringValue);
  };
  const isPeadPcaProduct = (product) => {
    const variant = product.Variante;
    const regex = /(PCA-M60)|(PEAD-M60)|(PEAD-M71)/g;
    const found = variant.match(regex);
    return found !== null && found.length > 0;
  };
  const handleSelection = (product, step, position) => {
    
    setModalIsOpen(false);
    let currentSelection = productSelection;
    if (step === "interne") {
      const [phase, room, element] = position.split("_");
      const actualState = JSON.parse(JSON.stringify(state));
      actualState[`room${room}Available`] = false;
      actualState[`step${Number(phase) + 1}Enabled`] = true;
      setState(actualState);

      if (!currentSelection[step][Number(room) - 1])
        currentSelection[step][Number(room) - 1] = [];
      currentSelection[step][Number(room) - 1] = { product, position };
      if (isFirstInternUnit) {
        setIsPeadPcaSelected(isPeadPcaProduct(product));
        setIsFirstInternUnit(false);
      }

      setCurrentLimits({
        unit:
          (currentLimits && currentLimits.unit ? currentLimits.unit : 0) + 1,
        capacity:
          (currentLimits && currentLimits.capacity
            ? currentLimits.capacity
            : 0) + forceNumber(product["Indice capacità"]),
        vIndex:
          (currentLimits && currentLimits.vIndex ? currentLimits.vIndex : 0) +
          forceNumber(product["Indice Vi"]),
      });
      // console.log('currentLimits', currentLimits,product)
    } else {
      let actualState = JSON.parse(JSON.stringify(state));
      if (product[0].hasOwnProperty("N. max unità collegabili")) {
        setShowConfirmFirstStep(true);
        actualState = JSON.parse(JSON.stringify(defaultState));
        currentSelection = defaultStateSelection;
        setState(JSON.parse(JSON.stringify(defaultState)));
        setCurrentLimits({
          unit: 0,
          capacity: 0,
          vIndex: 0,
        });
        setIsFirstInternUnit(true);
        setIsPeadPcaSelected(false);
        setMaxLimits({
          unit: Number(product[0]["N. max unità collegabili"]),
          capacity: Number(product[0]["Capacità max. collegabile"]),
          vIndex: forceNumber(product[0]["Indice Vi max. collegabile"]),
        });
      }
      actualState[`step${Number(currentStep) + 1}Enabled`] = true;
      setState(actualState);

      if (product.Variante === "PXZ-5F85VG") {
        setShowConfirmFirstStep(true);
      }
      currentSelection[step] = product;
    }
    setProductSelection(currentSelection);
    setProductsOverlay(currentSelection, position);
  };
  useEffect(() => {
    // console.log('maxLimits',maxLimits)
  }, [maxLimits]);
  

  useEffect(() => {
    checkErrors(currentLimits, maxLimits);
    handleResize()
    // console.log('currentLimits',currentLimits)
  }, [currentLimits]);

  const setProductsOverlay = (products) => {
    const allProducts = [];
    if (products) {
      if (products.esterne.length > 0) allProducts.push(...products.esterne);
      if (products.idroniche.length > 0)
        allProducts.push(...products.idroniche);
      if (products.interne.length > 0) allProducts.push(...products.interne);
    }
    const allBgs = allProducts.map((prod) => {
      let imgName = "";
      let imgPosition = "";
      if (prod && !prod.hasOwnProperty("position")) {
        imgName = prod.Tipo === "Idronica" ? prod.ID : prod.Variante;
        if (prod.Tipo === "Idronica") {
          imgPosition = "2_1_1";
          if (prod.ID === "inwall20") imgPosition = "2_2_1";
        } else {
          imgPosition = "1_1_1";
        }
      } else {
        if (prod) {
          imgPosition = prod.position;
          imgName = prod.product.Modello;
        }
      }
      return `url(/img/Products/${imgPosition}_${imgName}.png)`;
    });
    setProductBgs(allBgs.join(","));
  };

  const getInternUnitDetail = (position) => {
    const [step, room, element] = position.split("_");
    return {
      step,
      room,
      element,
    };
  };

  const getInternElements = (arr) => {
    return arr.filter((el) => {
      return typeof el === "object" && el !== null;
    });
  }

  const unsetRoom = (position) => {
    const internDetail = getInternUnitDetail(position);
    const currentSelection = JSON.parse(JSON.stringify(productSelection));
    setCurrentLimits({
      unit: (currentLimits && currentLimits.unit ? currentLimits.unit : 0) - 1,
      capacity:
        (currentLimits && currentLimits.capacity ? currentLimits.capacity : 0) -
        forceNumber(
          currentSelection.interne[
            Number(getInternUnitDetail(showRoomModalChange.position).room) - 1
          ].product["Indice capacità"]
        ),
      vIndex:
        (currentLimits && currentLimits.vIndex ? currentLimits.vIndex : 0) -
        forceNumber(
          currentSelection.interne[
            Number(getInternUnitDetail(showRoomModalChange.position).room) - 1
          ].product["Indice Vi"]
        ),
    });
    currentSelection.interne[
      Number(getInternUnitDetail(showRoomModalChange.position).room) - 1
    ] = null;
    const actualState = JSON.parse(JSON.stringify(state));
    actualState[`room${internDetail.room}Available`] = true;
    const filteredRoomElements = currentSelection.interne.filter((el) => {
      return typeof el === "object" && el !== null;
    });
    if (filteredRoomElements.length === 0) {
      setIsFirstInternUnit(true);
      setIsPeadPcaSelected(false);
      actualState.step4Enabled = false;
    }
    setProductSelection(currentSelection);
    setProductsOverlay(currentSelection);
    setState(actualState);
  };

  const resolveClick = (content) => {
    console.log('resolveClick',isPeadPcaSelected)
    if (!isPeadPcaSelected) {
      if (content) {
        const position = content.id.replace("active_area_", "");
        const [step, room, element] = position.split("_");
        // console.log('handleClickArea',state,`room${room}Available`,state[`room${room}Available`] )
        if (
          !state.reachedLimitUnit &&
          !state.reachedLimitCapacity &&
          !state.reachedLimitVIndex
        ) {
          if (state[`room${room}Available`]) {
            setPositionRoom(position);
            setCurrentStep(3);
            setModalIsOpen(true);
          } else {
            setShowRoomModalError(true);
          }
        } else {
          setShowModalError(true);
        }
      }
    } else {
      setShowModalError(true);
    }
  };
  const handleClickArea = (content) => {
    console.log('handleClickArea isPeadPcaSelected', isPeadPcaSelected)
    
      setAreaClick(content);
    
  };

  const handleClickPrint = () => {
    if (state.step4Enabled) {
      // const string = renderToString(<ContentPdf currentSelection={productSelection} />);
      window.scrollTo({top:0})
      setPrintPdf(true);
    }
  };
  const downloadBuffer =(arrayBuffer, fileName) => {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(new Blob(
    [ arrayBuffer ],
    { type: 'application/pdf' }
  ))
  a.download = fileName
  a.click()
}
const toDataUrl = async function (url) {
  //Convert to base64
  var res = await fetch(url);
  var blob = await res.blob();

  return new Promise((resolve, reject) => {
    var reader  = new FileReader();
    reader.addEventListener("load", function () {
        resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
};
const getImage = (url) => {
  return toDataUrl(url).then(img=>img)
}
const base64ToArrayBuffer = (base64) => {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}
const base64ToArrayBufferAsync = (dataUrl) => {
  // var dataUrl = "data:application/octet-binary;base64," + base64;

  return fetch(dataUrl)
    .then(res => res.arrayBuffer())
    .then(buffer => {
      // console.log("base64 to buffer: " + new Uint8Array(buffer));
      return buffer
      // return new Uint8Array(buffer)
    })
}
  const createPdf = async () => {
      const string = document.querySelector(".wrapper-pdf.page-1");
      const string2 = document.querySelector(".wrapper-pdf.page-2");
      const home = document.querySelector('.wrapper-configurator')
      const urlPdf = window.location.origin + '/img/pdf/'+getLocalePath(locale)+'/'+lang+'/ECODAN-PAG3.pdf';
      const urlPdf4 = window.location.origin + '/img/pdf/'+getLocalePath(locale)+'/'+lang+'/ECODAN-PAG4.pdf';
      const pdfBytes = await fetch(urlPdf).then((res) => res.arrayBuffer())
      const pdfBytes2 = await fetch(urlPdf4).then((res) => res.arrayBuffer())
      // const to = setTimeout(async () => {
        // const pageImage = await html2canvas(string2).then(canvas => canvas.toDataURL('image/jpeg'))
        
        // // window.html2pdf(string);
        let canvasScreenshot = ''
        let imgWidth=0
        let pageHeight=0
        let imgHeight=0
        let heightLeft=0
        // let pdfGenerator = window.html2pdf()
        //   // let pdfGenerator2 = window.html2pdf()
        //   pdfGenerator = pdfGenerator.from(string).set({
        //     image: { type: 'jpeg', quality: 1 },
        //   })
        //   // pdfGenerator2 = pdfGenerator2.from(string2).set({
        //   //   html2canvas:{scale:3},
        //   //   image: { type: 'jpeg', quality: 1 },
        //   // })
        //   // const secondPage = await pdfGenerator2.output('arraybuffer').then(async arr => arr)
        //   const firstPages = pdfGenerator.output('arraybuffer').then(async arr => {
            
        //     const secondPage = await base64ToArrayBufferAsync(pageImage)
        //     console.log('arr', arr)
        //     console.log('secondPage', secondPage)
        //     const pdfDoc2 = await PDFDocument.create()
        //     const image2 = await pdfDoc2.embedJpg(secondPage)
        //     const page2 = pdfDoc2.addPage(PageSizes.A4)
        //     console.log('page2',page2)
        //     page2.drawImage(image2)
        //     console.log('creazione pdf', image2, page2)
        //     const pdfDoc = await PDFDocument.load(arr, PageSizes.A4)
        //     const pdfDoc3 = await PDFDocument.load(pdfBytes, PageSizes.A4)
        //     const pdfDoc4 = await PDFDocument.load(pdfBytes2, PageSizes.A4)
            
        //     const pdfdoc2pages = pdfDoc2.getPages()
            
        //     console.log('pdfdoc2pages', pdfdoc2pages)
        //     pdfdoc2pages.forEach(async (page,i) => {
        //       const [existingPage] = await pdfDoc.copyPages(pdfDoc2, [i])
        //       pdfDoc.addPage(existingPage)
        //     })
        //     const pdfdoc3pages = pdfDoc3.getPages()
        //     pdfdoc3pages.forEach(async (page,i) => {
        //       const [existingPage] = await pdfDoc.copyPages(pdfDoc3, [i])
        //       pdfDoc.addPage(existingPage)
        //     })
        //     const pdfdoc4pages = pdfDoc3.getPages()
        //     pdfdoc4pages.forEach(async (page,i) => {
        //       const [existingPage] = await pdfDoc.copyPages(pdfDoc4, [i])
        //       pdfDoc.addPage(existingPage)
        //     })
        //     const finalPdf = await pdfDoc.save()

        //     downloadBuffer(finalPdf, 'Sistema Ecodan.pdf')
        //   })
        html2canvas(home).then(async canvas => {
          // imgWidth = 188;
          // pageHeight = 295;
          // imgHeight = canvas.height * imgWidth / canvas.width;
          // heightLeft = imgHeight;

          canvasScreenshot = canvas.toDataURL('image/jpeg')
          // const page2 = html2canvas(string2).
          let pdfGenerator = window.html2pdf()
          let pdfGenerator2 = window.html2pdf()
          pdfGenerator = pdfGenerator.from(string).set({
            html2canvas:{
              scale:3
            },
            image: { type: 'jpeg', quality: 1 },
          })
          pdfGenerator2 = pdfGenerator2.from(string2).set({
            html2canvas:{
              scale:3,
            },
            image: { type: 'jpeg', quality: 1 },
          })
          const secondPage = await pdfGenerator2.output('arraybuffer').then(async arr => arr)
          const firstPages = pdfGenerator.output('arraybuffer').then(async arr => {
            console.log('secondPage', secondPage)
            
            const pdfDoc = await PDFDocument.load(arr, PageSizes.A8)
            const pdfDoc2 = await PDFDocument.load(secondPage, PageSizes.A8)
            const pdfDoc3 = await PDFDocument.load(pdfBytes, PageSizes.A8)
            const pdfDoc4 = await PDFDocument.load(pdfBytes2, PageSizes.A8)
            
            const pdfdoc2pages = pdfDoc2.getPages()
            pdfdoc2pages.forEach(async (page,i) => {
              const [existingPage] = await pdfDoc.copyPages(pdfDoc2, [i])
              pdfDoc.addPage(existingPage)
            })
            const pdfdoc3pages = pdfDoc3.getPages()
            pdfdoc3pages.forEach(async (page,i) => {
              const [existingPage] = await pdfDoc.copyPages(pdfDoc3, [i])
              pdfDoc.addPage(existingPage)
            })
            const pdfdoc4pages = pdfDoc4.getPages()
            pdfdoc4pages.forEach(async (page,i) => {
              const [existingPage] = await pdfDoc.copyPages(pdfDoc4, [i])
              pdfDoc.addPage(existingPage)
            })
            const finalPdf = await pdfDoc.save()

            downloadBuffer(finalPdf, 'EcodanMultiPXZ.pdf')
            const to = setTimeout(()=>{
              setPrintPdf(false)
              clearTimeout(to);
            }, 1000);
          })


        });
  }
  useEffect(() => {
    const body = document.querySelector('body')
    if (printPdf) {
      createPdf()
      body.style.overflowY = 'hidden'
    } else {
      const to = setTimeout(()=>{
        body.style.overflowY = 'auto'
        clearTimeout(to)
      }, 2000)
    }
  }, [printPdf]);

  useEffect(() => {
    resolveClick(areaClick);
  }, [areaClick]);

  const openModal = (content) => {
    setCurrentStep(Number(content.dataset.step));
    setModalIsOpen(true);
  };

  const handleClose = (val) => {
    setModalIsOpen(val);
  };
  const handleCloseMessage = () => {
    setModalMessageOpen(false);
  };

  const handleCloseError = () => {
    setShowModalError(false);
  };

  const showModalPreviewClick = (val) => {
    if (state.step4Enabled) {
      setShowModalPreview(val);
    }
  };

  const handleChangeInternUnit = (position, sku) => {
    if(isPeadPcaProduct({'Variante': sku})) {
      setIsPeadPcaSelected(false);
      setShowModalError(false)
    }
    setShowRoomModalChange({
      show: true,
      position,
      sku,
    });
  };

  const dataPdf = async () => {
    const src = toDataUrl(`${window.location.origin}/img/pdf/Ecodan-pag2-grid.svg`).then(img => img)
    return (<div>{src}</div>)
  }

  return (
    <div className="content">
      <div className="wrapper-configurator">
        <img ref={imageRef} src={home} alt="main" />
        <div
          className="wrapper-products-overlay"
          style={{
            backgroundImage: productBgs,
          }}
        ></div>
        

        {
          !showModalPreview &&
          !showModalError &&
          !showRoomModalError &&
          !modalIsOpen &&
          !printPdf &&
          state.step3Enabled && (
            <ActiveAreas width={imageW} handleClick={handleClickArea} state={state} />
          )}
        {/* <Translation>{t => <ChangeLang t={t} />}</Translation> */}
        <div
          style={{display: printPdf ? 'none':'block'}}
          className="btn-help"
          onClick={() => {
            setShowHelp(true);
            setModalMessageOpen(true);
          }}
        >
          ?
        </div>
      </div>
      {state && (
        <UnitBlocks
          openModal={openModal}
          currentStep={currentStep}
          productSelection={productSelection}
          state={state}
          handlePreview={showModalPreviewClick}
          handleClickPrint={handleClickPrint}
          handleChangeInternUnit={handleChangeInternUnit}
        />
      )}

      <Modal
        modalIsOpen={
          modalIsOpen && currentStep && state[`step${currentStep}Enabled`]
        }
        handleClose={handleClose}
      >
        {currentStep === 1 && state.step1Enabled && !showConfirmFirstStep && (
          <ContentModalFirstStep
            data={dataset && dataset["1"].Esterna}
            currentSelection={productSelection.esterne}
            handleSelection={handleSelection}
          />
        )}
        {currentStep === 1 && state.step1Enabled && showConfirmFirstStep && (
          <div className="wrapper-modal-content">
            <div className="modal-title">{t('VUOI RIMUOVERE QUESTA UNITà?')}</div>
            <div className="modal-content">
              {productSelection.esterne[0].Variante} <br />
              <br />
              {t('Rimuovendo l\'unità esterna anche le altre unità già selezionate saranno rimosse')}
            </div>
            <div className="modal-cta wrapper-cta">
              <div
                className="modal-button left back"
                onClick={() => {
                  handleResize()
                  setModalIsOpen(false);
                }}
              >
                {t('annulla')}
              </div>
              <div
                className="modal-button right"
                onClick={() => {
                  setShowConfirmFirstStep(false);
                }}
              >
                {t('conferma')}
              </div>
            </div>
          </div>
        )}
        {currentStep === 2 && state.step2Enabled && (
          <ContentModalSecondStep
            data={dataset && dataset["2"].Idronica}
            currentSelection={productSelection.idroniche}
            handleSelection={handleSelection}
          />
        )}
        {currentStep === 3 && state.step3Enabled && (
          <ContentModalThirdStep
            isPeadPcaSelected={isPeadPcaSelected}
            isFirstInternUnit={isFirstInternUnit}
            data={dataset && dataset["3"]}
            currentSelection={productSelection.idroniche}
            handleSelection={handleSelection}
            position={positionRoom}
            limits={{ currentLimits, maxLimits }}
            handleClose={handleClose}
            firstStepSelection={productSelection.esterne[0]}
          />
        )}
      </Modal>
      <Modal
        modalIsOpen={modalMessageOpen}
        handleClose={handleCloseMessage}
        showClose={true}
      >
        {showHelp && (
          <div className="wrapper-modal-content">
            <div className="modal-title">
              {t('Configuratore sistema Ecodan Multi PXZ')}
            </div>
            <div className="modal-content">
              <b>{t('1. Seleziona Unità Esterna')}</b>
              <p>{t('L\'unità esterna determina la potenza dell\'impianto')}</p>
              <b>{t('2. Seleziona Unità Idronica')}</b>
              <p>
                {t('Seleziona la tipologia di unità idronica prima di procedere con la configurazione degli ambienti')}
              </p>
              <b>{t('3. Seleziona Unità Interne')}</b>
              <p>
                {t('Utilizza il configuratore per selezionare le stanze e procedere all\'installazione delle unità a parete, a pavimento, a soffitto o canalizzate')}
              </p>
              <b>{t('4. Verifica e scarica la tua configurazione')}</b>
              <p>
                {t('Il configuratore verifica che le potenze installate siano coerenti con la potenza disponibile. Modifica se necessario la configurazione e scarica in PDF le tue scelte')}
              </p>
            </div>
            <div
              className="wrapper-cta modal-cta"
              style={{ textAlign: "center" }}
            >
              <div
                className="modal-button"
                onClick={handleCloseMessage}
                style={{ float: "none" }}
              >
                {t('OK')}
              </div>
            </div>
          </div>
        )}
      </Modal>
      {showRoomModalError && (
        <Modal
          modalIsOpen={showRoomModalError}
          handleClose={() => {
            setShowRoomModalError(false);
          }}
          showClose={true}
        >
          <div className="wrapper-modal-content">
            <div className="modal-title">
              {t('HAI GIA\' INSTALLATO UN PRODOTTO IN QUESTA STANZA')}
            </div>
            <div className="modal-content">
              {t('Modifica la configurazione se vuoi installare un prodotto in questa posizione')}
            </div>
          </div>
        </Modal>
      )}
      {showRoomModalChange.show && (
        <Modal
          modalIsOpen={showRoomModalChange.show}
          handleClose={() => {
            const activeRoom = showRoomModalChange;
            setShowRoomModalChange({
              show: false,
              position: activeRoom.position,
              sku: activeRoom.sku,
            });
          }}
          showClose={true}
        >
          <div className="wrapper-modal-content">
            <div className="modal-title">{t('VUOI RIMUOVERE QUESTA UNITà?')}</div>
            <div className="modal-content">
              {
                productSelection.interne[
                  Number(
                    getInternUnitDetail(showRoomModalChange.position).room
                  ) - 1
                ]?.product.Variante
              }{" "}
              <br />
              <br />
              {t('Dopo la modifica potrai aggiungere nuovi prodotti')}
            </div>
            <div className="modal-cta wrapper-cta">
              <div
                className="modal-button left back"
                onClick={() => {
                  const activeRoom = showRoomModalChange;
                  setShowRoomModalChange({
                    show: false,
                    position: activeRoom.position,
                    sku: activeRoom.sku,
                  });
                }}
              >
                {t('annulla')}
              </div>
              <div
                className="modal-button right"
                onClick={() => {
                  const activeRoom = showRoomModalChange;
                  setShowRoomModalChange({
                    show: false,
                    position: activeRoom.position,
                    sku: activeRoom.sku,
                  });
                  unsetRoom(showRoomModalChange.position);
                }}
              >
                {t('conferma')}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {showModalError && (
        <Modal
          modalIsOpen={showModalError}
          handleClose={handleCloseError}
          showClose={true}
        >
          {isPeadPcaSelected && (
            <>
              <div className="wrapper-modal-content">
                <div className="modal-title">
                  {t('NON È POSSIBILE INSTALLARE PEAD-M60/71 E PCA-M60 CON ALTRE UNITÀ AD ESPANSIONE')}
                </div>
                <div className="modal-content">
                  {t('Modifica la configurazione se vuoi installare un prodotto in questa posizione')}
                </div>
              </div>
            </>
          )}
          {!isPeadPcaSelected && state.reachedLimitUnit && (
            <>
              <div className="wrapper-modal-content">
                <div className="modal-title">
                  {t('Hai superato il numero massimo di unità connettibili')}
                </div>
                <div className="modal-content">
                  {t('Modifica la configurazione se vuoi installare un prodotto in questa posizione')}
                </div>
              </div>
            </>
          )}
          {!isPeadPcaSelected && !state.reachedLimitUnit && (
            <>
              <div className="wrapper-modal-content">
                <div className="modal-title">
                  {t("HAI SUPERATO LA POTENZA DISPONIBILE DELL'IMPIANTO")}
                </div>
                <div className="modal-content">
                  {t('Modifica la configurazione se vuoi installare un prodotto in questa posizione')}
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
      {showModalPreview && (
        <Modal
          modalIsOpen={showModalPreview}
          handleClose={() => {
            setShowModalPreview(false);
          }}
          showClose={true}
        >
          <ContentModalPreview currentSelection={productSelection} />
        </Modal>
      )}
      {printPdf && (
        <Modal
          modalIsOpen={printPdf}
          
          showClose={false}
        >
          <div className="wrapper-title" style={{textAlign: 'center', padding:20}}>
            {t('Generazione pdf in corso')}
          </div>
        </Modal>
      )}
      <div style={{display: 'none'}}>

      {printPdf && 
      <>
        <ContentPdf currentSelection={productSelection} extraClass={'page-1'} lang={lang} locale={getLocalePath(locale)}>
        <div className="wrapper-configurator">
            {/* <img src={page1} alt=""/> */}
            <div style={{marginTop:150, position:'relative'}}>
            <div
              className="wrapper-products-overlay"
              style={{
                backgroundImage: productBgs,
              }}
            >

            </div>
            <img src={home} alt="main" />
            </div>
        </div>
        </ContentPdf>
        <ContentPdf extraClass={`qty-${getInternElements(productSelection.interne).length} page-2`} currentSelection={getInternElements(productSelection.interne).length} lang={lang} locale={getLocalePath(locale)}>
          <div className="content">
            {
              
              getInternElements(productSelection.interne).map((product, i) => {
                return (
                  <div className={`product interno interno-${i}`}>
                    <div className="wrapper-name">
                      {product.product.Variante}
                    </div>
                    <div className="wrapper-image">
                      <img src={`/img/Thumbnails/thumb_${product.product.Modello}.jpg`} alt="" />
                    </div>
                    
                  </div>
                )
              })
            }
            {
              
              productSelection.esterne.map((product) => {
                return (
                  <div className={`product esterno`}>
                    <div className="wrapper-name">
                      {product.Variante}
                    </div>
                    <div className="wrapper-image">
                      <img src={`/img/Thumbnails/thumb_${product.Modello}.jpg`} alt="" />
                    </div>
                    
                  </div>
                )
              })
            }
            {
              
              productSelection.idroniche.map((product) => {
                return (
                  <div className={`product idroniche`}>
                    <div className="wrapper-name">
                      {product.Variante}
                    </div>
                    <div className="wrapper-image">
                      <img src={`/img/Thumbnails/thumb_${product.ID}.jpg`} alt="" />
                    </div>
                    
                  </div>
                )
              })
            }
            
          </div>
        </ContentPdf>
        </>
        } 
       </div>
    </div>
  );
}

export default Home;
