import { createContext, useContext, useState } from "react";

const ProductsContext = createContext();

const ProductsProvider = ({ children }) => {
  const [data, setData] = useState({
    esterne: [],
    idroniche: [],
    interne: [],
  });

  return (
    <ProductsContext.Provider value={{data, setData}}>
      {children}
    </ProductsContext.Provider>
  )
};


const useProductsContext = () => useContext(ProductsContext)

export { useProductsContext, ProductsProvider}